<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <div>
    <openapi-explorer
      :spec-url="openapiSpecificationUrl"
      :use-path-in-nav-bar="true">
      
    </openapi-explorer>
  </div>
</template>
<script>

import 'openapi-explorer';

export default {
  data () {
    return {
      openapiSpecificationUrl: `${this.$store.state.apiUrl}openapi`,
    }
  }
}
</script>